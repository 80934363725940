* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--tg-theme-bg-color);
    font-family: "Roboto";
    color: black;
    height: 100%;
    width: 550px;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Regular.ttf") format('truetype');

}

* {
    max-width: 550px;
}


