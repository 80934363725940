.loader {
  display: flex;
  border: 16px solid #323337; /* Light grey */
  border-top: 16px solid #69b42e; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 140vh;
  width: 100%;
  margin: auto;
}
